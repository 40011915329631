var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack } from '@mui/material';
// components
import Image from '../../components/image';
//
import loginImage from '../../assets/images/illustrations/At the office-rafiki.png';
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from './styles';
export default function LoginLayout(_a) {
    var children = _a.children, illustration = _a.illustration;
    return (_jsxs(StyledRoot, { children: [_jsxs(StyledSection, { children: [_jsx(Image, { disabledEffect: true, visibleByDefault: true, alt: "auth", src: illustration || loginImage, sx: { maxWidth: 720 } }), _jsx(StyledSectionBg, {})] }), _jsx(StyledContent, { children: _jsxs(Stack, __assign({ sx: { width: 1 } }, { children: [" ", children, " "] })) })] }));
}
